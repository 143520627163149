import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,7,6,8,9,4];

export const dictionary = {
		"/[[language=language]]/(index)": [~12,[2],[3]],
		"/[[language=language]]/(with-nav)/careers/jobs": [~16,[2,5,7],[3]],
		"/[[language=language]]/(with-nav)/careers/jobs/[id]": [~17,[2,5,7],[3]],
		"/[[language=language]]/(with-nav)/cookie-policy": [~18,[2,5],[3]],
		"/[[language=language]]/(with-nav)/inclusion": [~19,[2,5],[3]],
		"/[[language=language]]/(with-nav)/inclusion/workforce-data-2022": [~20,[2,5],[3]],
		"/[[language=language]]/(with-nav)/(news)/(posts)/inside/[slug]": [~13,[2,5,6],[3]],
		"/[[language=language]]/investors": [~25,[2],[3]],
		"/[[language=language]]/(with-nav)/legal": [~21,[2,5],[3]],
		"/[[language=language]]/(with-nav)/legal/licenses": [~23,[2,5],[3]],
		"/[[language=language]]/(with-nav)/legal/[slug]": [~22,[2,5],[3]],
		"/[[language=language]]/(with-nav)/mediakit": [~24,[2,5],[3]],
		"/[[language=language]]/(with-nav)/(news)/news/(landing page)": [~14,[2,5,6],[3]],
		"/[[language=language]]/(with-nav)/(news)/news/(tags)/[slug]": [~15,[2,5,6],[3]],
		"/preview": [~26,[8]],
		"/preview/component/[id]": [~27,[8,9]],
		"/[[language=language]]/(campaign)/[campaign]": [~10,[2,4],[3]],
		"/[[language=language]]/(campaign)/[campaign]/[...slug]": [~11,[2,4],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';