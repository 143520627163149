import * as Sentry from '@sentry/sveltekit';
import { env as publicEnv } from '$env/dynamic/public';

Sentry.init({
  dsn: 'https://5ef33e2829b74ee4839ef3c6a4ca4516@o160250.ingest.sentry.io/4505285602246656',
  sampleRate: publicEnv.PUBLIC_ENVIRONMENT === 'production' ? 0.3 : 1,
  tracesSampleRate: publicEnv.PUBLIC_ENVIRONMENT === 'production' ? 0.3 : 1,
  environment: publicEnv.PUBLIC_ENVIRONMENT ?? 'development'
});

export const handleError = Sentry.handleErrorWithSentry();
